/**
 * @author Akshay Kumar Singh
 * @file Helper Util
 * @flow
 */
import CONFIG from '../../configuration';
import { getData } from './storage.util';
import ROUTES from '../constants/routes';

/**
 * @description This function is used to fomat the date in a specific format.
 * @param date Date that will be formatted
 */
export const formatDate = (date) => {
    let formatedDate;
    if (date) {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        const newDate = new Date(date);
        var day = newDate.getDate();
        var index = newDate.getMonth();
        var year = newDate.getFullYear();

        formatedDate = '' + (day < 10 ? `0${day}` : day) + ' ' + months[index] + ' ' + year;
    }
    return formatedDate;
};

/**
 * @description This is used to get the min date and max date.
 * @param data Data used to find the min and max date.
 */
export const getMinMaxDate = (data) => {
    let minDate = data.length ? data[0].start_date : '',
        maxDate = data.length ? data[0].start_date : '';
    data.map((item) => {
        if (new Date(item.start_date) < new Date(minDate)) {
            minDate = item.start_date;
        }
        if (new Date(item.start_date) > new Date(maxDate)) {
            maxDate = item.start_date;
        }
    });
    return { minDate, maxDate };
};

/**
 * @description This is used to sort the array of object.
 * @param arrObj Array of object.
 * @param key sort bases of this key
 * @param order Order of sort (By default assending sort)
 */
export const sortArrObj = (arrObj, key, order = 'asc') => {
    return arrObj.sort(compareValues(key, order));
};

/**
 * @description This is used to compare two values.
 * @param key key to compare
 * @param order Order of sort (By default assending sort)
 */
const compareValues = (key, order = 'asc') => {
    return (a, b) => {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
        }

        const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return order === 'desc' ? comparison * -1 : comparison;
    };
};

/**
 * @description This is used to check the status of a audit.
 * @param status status of the audit
 */
export const checkAuditStatus = (status) => {
    switch (status) {
        case 0:
            return 'In Draft';
        case 1:
            return 'In Progress';
        case 2:
            return 'Completed';
        default:
    }
};

/**
 * @description This is used to check the status of a task.
 * @param status status of the task
 */
export const checkTaskStatus = (status) => {
    switch (status) {
        case 0:
            return 'Drafted';
        case 1:
            return 'In Progress';
        case 2:
            return 'In Review';
        case 3:
            return 'Completed';
        default:
    }
};

/**
 * @description Used to get the id of audit based on audit name.
 * @param name name of the audit.
 */
export const getAuditId = (name) => {
    switch (name) {
        case 'Work Order Compliance':
            return 1;
        case 'Parts Room  Inventory Compliance':
            return 2;
        case 'Line Equipment and Injection':
            return 3;
        case 'Facilities':
            return 4;
        case 'Utilities':
            return 5;
        case 'Batching':
            return 6;
        case 'Processing':
            return 7;
        default:
            return 0;
    }
};

/**
 * @description Get base route based on user role
 */
export const getRoleBasedRoute = () => {
    let role = getData('loginData')?.userData?.role_name,
        route = ROUTES.DASHBOARD;
    switch (role) {
        case 'audit manager':
            return route;

        case 'auditor':
            return (route = ROUTES.AUDITOR_MYTASK);

        case 'reviewer':
            return (route = ROUTES.REVIEWER_MYTASK);

        case 'plant manager':
            return (route = ROUTES.PLM_MYTASK);

        case 'plant auditor':
            return (route = ROUTES.PLA_MYTASK);

        case 'plant reviewer':
            return (route = ROUTES.PLR_MYTASK);

        default:
            return route;
    }
};

/**
 * @description get the condition to show the observation based on tab.
 * @param tab tab name
 */
export const showObservation = (tab) => {
    switch (tab) {
        case 'PARTS ROOM SAFETY & SECURITY':
            return true;
        case 'Preventative Maintenance':
            return true;
        case 'Machine Health':
            return true;
        case 'General Audit':
            return true;
        case 'PM Quality Assessment':
            return true;
        case 'General Utilities':
            return true;
        case 'General Facilities':
            return true;
        case 'General Batching':
            return true;
        case 'General Processing':
            return true;
        case 'Diagnostic Visit':
            return false;
        default:
            return false;
    }
};

/**
 * @description get the type of file.
 * @param type type of file.
 */
export const checkFileType = (type) => {
    switch (type) {
        case 'image/jpeg':
            return true;
        case 'image/png':
            return true;
        case 'image/jpg':
            return true;
        case 'image/svg+xml':
            return true;
        default:
            return false;
    }
};

/**
 * @description get the type of file.
 * @param type type of file.
 */
export const checkVideoType = (type) => {
    switch (type) {
        case 'video/mp4':
            return true;
        case 'video/x-flv':
            return true;
        case 'application/x-mpegURL':
            return true;
        case 'video/MP2T':
            return true;
        case 'video/3gpp':
            return true;
        case 'video/quicktime':
            return true;
        case 'video/x-msvideo':
            return true;
        case 'video/x-ms-wmv':
            return true;
        default:
            return false;
    }
};

export const showFilterType = (name, role) => {
    switch (role) {
        case 'audit manager':
            return true;
        case 'auditor':
        case 'plant auditor': {
            if (name == 'In Review') {
                return false;
            } else {
                return true;
            }
        }
        case 'reviewer':
        case 'plant reviewer': {
            if (name == 'In Progress') {
                return false;
            } else {
                return true;
            }
        }
        default:
            return true;
    }
};

/**
 * @description This is used to generate the report.
 * @param taskInfo task details
 */
export const generateReport = (taskInfo) => {
    let { audit_id } = taskInfo;
    window.location.href = `${CONFIG.generateReport}?id=${audit_id}`;
};
