import { LABLES } from '../../shared/constants/data';
import React, { useState, Component } from 'react';
import Modal from 'react-responsive-modal';
import { spinnerService } from '../../shared/services/spinner.service';
import LANGUAGECONSTANT from '../../shared/constants/language';
import CONFIG from '../../configuration';
import { logoutUnauthorizedUser } from '../../shared/utils/auth.util';
import AlertModal from '../../shared/modals/AlertModal';
import Axios from 'axios';
import { getData } from '../../shared/utils/storage.util';

class DeleteAuditWriteBack extends Component {
    constructor(props) {
        super(props);
    }

    handleProceed = async (e) => {
        let opSeq = '';
        e.preventDefault();

        let language = this.props?.langData?.language || 'en';
        let { audit_id, task_id, task_name } = this.props.taskInfo;
        let { ques_id, operation_numb, short_ques, long_ques, max, min, isLocal } =
            this.props.quesData;

        if (task_name == 'Utilities') {
            if (this.props.quesData?.isLocal) {
                opSeq = this.props.quesData?.utility_ques_id;
            } else {
                opSeq = this.props.quesData?.operation_numb;
            }
        } else if (task_name == 'Facilities') {
            if (this.props.quesData?.isLocal) {
                opSeq = this.props.quesData?.facility_ques_id;
            } else {
                opSeq = this.props.quesData?.operation_numb;
            }
        } else if (task_name == 'Batching') {
            if (this.props.quesData?.isLocal) {
                opSeq = this.props.quesData?.batching_ques_id;
            } else {
                opSeq = this.props.quesData?.operation_numb;
            }
        } else if (task_name == 'Processing') {
            if (this.props.quesData?.isLocal) {
                opSeq = this.props.quesData?.processing_ques_id;
            } else {
                opSeq = this.props.quesData?.operation_numb;
            }
        } else if (task_name == 'Line Equipment and Injection') {
            if (this.props.quesData?.isLocal) {
                opSeq = this.props.quesData?.pm_mh_ques_id;
            } else {
                opSeq = this.props.quesData?.operation_numb;
            }
        }

        let data = {
            audit_id: audit_id,
            task_id: task_id,
            ques_id: ques_id,
            scenario: this.props.applicable,
            op_seq: opSeq,
            description: short_ques,
            long_description: long_ques,
            min: min,
            max: max,
            isLocal: isLocal
        };
        spinnerService.show(
            'Oval',
            LANGUAGECONSTANT[this.props?.langData?.langType || 'ENG'].STRINGS.UploadingImage
        );
        const config = {
            headers: {
                authorization: `Bearer ${getData('token')}`,
                language: language
            }
        };
        let response = await Axios.post(CONFIG.addWBQues, data, config);
        spinnerService.hide('Oval');
        this.onCancel();
        this.props.setShowForm(false);
        if (response && response.data && response.data.responseCode == 200) {
            AlertModal.showAlert(response.data.message);
            this.props.getWbQuesApi();
        } else if (response && response.data && response.data.responseCode == 401) {
            logoutUnauthorizedUser(this, response.data.message);
        } else {
            AlertModal.showAlert(response.data.message);
        }
    };

    onCancel = () => {
        this.props.setShowDeleteConfirm(false);
        this.props.setApplicable(null);
    };

    render() {
        let { lang, showDeleteConfirm, quesData } = this.props;

        return (
            <>
                <Modal open={showDeleteConfirm} onClose={this.onCancel} center>
                    <div className="popup-cntnt popuptext">
                        <center>
                            <b>Confirm For Delete</b>
                        </center>
                    </div>

                    <br />
                    <div className="row">
                        <div>
                            <span>
                                <b>
                                    {
                                        LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                            .OPSEQUENCE
                                    }
                                </b>
                                : {quesData?.operation_numb}
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>
                                    {
                                        LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                            .DEPARTMENT
                                    }
                                </b>
                                : {quesData?.long_heading}
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>
                                    {
                                        LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                            .SHORTDESCRIPTION
                                    }
                                </b>
                                : {quesData?.short_ques}
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>
                                    {
                                        LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                            .LONGDESCRIPTION
                                    }
                                </b>
                                : {quesData?.long_ques}
                            </span>
                        </div>
                    </div>
                    <br />
                    <br />

                    <div className="btn-container">
                        <button className="btn-prmry btn-rqst-accs" onClick={this.onCancel}>
                            {lang.BUTTONS.Cancel}
                        </button>
                        <button
                            className="btn-prmry btn-rqst-accs"
                            onClick={(e) => this.handleProceed(e)}>
                            {lang.BUTTONS.Proceed}
                        </button>
                    </div>
                </Modal>
            </>
        );
    }
}

export default DeleteAuditWriteBack;
